import { groq } from 'next-sanity'

export const getAllCitiesInCountryByTypeQuery = (
  locale: AppLocale,
  countryId: string,
  offset?: number,
) => groq`
  *[_type == "city" && country->_id == "${countryId}" && defined(slug.${locale}.current)]
  | order(coalesce(number_activities.${locale}, 0) desc)
  {
   ${getReducedCityFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`
export const getAllCitiesInStateQuery = (
  locale: AppLocale,
  stateId: string,
  offset?: number,
) => groq`
  *[_type == "city" && state->_id == "${stateId}" && defined(slug.${locale}.current)]
  | order(coalesce(number_activities.${locale}, 0) desc)
  {
   ${getReducedCityFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllCitiesInRegionQuery = (
  locale: AppLocale,
  regionId: string,
  offset?: number,
) =>
  groq`*[_type == "city" && references("${regionId}") && defined(slug.${locale}.current)] 
  | order(coalesce(number_activities.${locale}, 0) desc) 
  {
    ${getReducedCityFields(locale)}
  } 
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllCitiesQuery = (locale: AppLocale, offset?: number) => groq`
  *[_type == "city" && defined(slug.${locale}.current)]
  | order(coalesce(number_activities.${locale}, 0) desc)
  {
   ${getReducedCityFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export function getAllCitiesInCountryQuery(
  locale: AppLocale,
  countryId: string,
  offset?: number,
) {
  return groq`
    *[_type == "city" && defined(slug.${locale}.current) && country->_id == "${countryId}"]
    | order(coalesce(number_activities.${locale}, 0) desc)
    {
      ${getReducedCityFields(locale)}
    }
    ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
  `
}

const getReducedCityFields = (locale: AppLocale) => ` 
  _id,
  _type,
  _updatedAt,
  "name": name.${locale},
  "h1": h1.${locale},
  slug,
  coverImage,
  priority,
  reviews,
  "number_activities": number_activities.${locale},
  number_locations,
`

export const getCityBySlugQuery = (locale: AppLocale, citySlug: string) => groq`
  *[_type == "city" && slug.${locale}.current == "${citySlug}"][0] {
    _id,
    _type,
    "name": name.${locale},
    "nameDescription": name_description.${locale},
    "h1": h1.${locale},
    slug,
    coverImage,
    "highlights": highlights.${locale},
    "description": description.${locale},
    "description2": description2.${locale},
    "description3": description3.${locale},
    "faq": faq.${locale},
    reviews,
    "widget": widget-> {
      _type,
      "button": button.${locale},
      "link": link.${locale},
      "name": name.${locale},
      image
    },
    "travelPlanWidget": travel_plan_widget-> {
      _type,
      "link": link.${locale},
      "sub_title": sub_title.${locale},
      "title": title.${locale},
      "text_above": text_above.${locale},
      image
    },
    "meta_title": meta_title.${locale},
    "meta_description": meta_description.${locale},
    "country": country->{
      _id,
      "name": name.${locale},
      slug
    },
    "state": state->{
      _id,
      "name": name.${locale},
      "slug": slug.${locale}.current
    },
    "number_activities": number_activities.${locale},
    number_locations,
    "dynamicFooter": dynamic_footer.${locale},
  }
`
